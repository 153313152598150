// @flow

import * as React from 'react'

type Props = {
  className?: string,
  title?: string,
  children: any,
}

function Section(props: Props): React.Node {
  let className = 'section'

  if (props.className) {
    className += ` ${props.className}`
  }

  return (
    <section className={className}>
      {props.title && <h2 className="headline headline--standard">{props.title}</h2>}
      {props.children}
    </section>
  )
}

export default Section