// @flow

import * as React from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Section from '../Section'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '../StatusMessages/StatusMessage'
import * as userService from '../../services/user/user'
import { isValidEmail } from '../../utils/email'
import IconTooltip from '../IconTooltip'
import BottomBar from './BottomBar.jsx'

type Props = {
  user: any,
  showStatusMessage: (string, string) => {},
  fetchUser: () => {},
}

export default function CompanySettings(props: Props): React.Node {
  const { user } = props
  const { t } = useTranslation()
  const [documentRetentionDuration, setDocumentRetentionDuration] = React.useState<string>('')

  React.useEffect(() => {
    userService.getDataRetentionPolicy()
      .then(({ data }) => setDocumentRetentionDuration(
        data.documentRetentionDuration ? String(data.documentRetentionDuration) : ''
      ))
  }, [])

  const canUpdate = user.permissions.isAdmin
  const initialValues = {
    companyName: user.companyName,
    registrationCode: user.companyRegistrationCode,
    address: user.companyAddress,
    companyEmail: user.companyEmailNotConfirmed ? user.companyEmailNotConfirmed : user.companyEmail,
    sendFullySignNotifications: user.companySendSignNotifications,
  }
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(t('validation.field_is_required')),
    registrationCode: Yup.string().required(t('validation.field_is_required')),
    address: Yup.string().required(t('validation.field_is_required')),
    companyEmail:
      Yup.string().required(t('validation.field_is_required'))
        .email(t('validation.value_is_not_a_valid_email'))
        .nullable()
        .test('validEmailAddressRequired', t('validation.value_is_not_a_valid_email'), isValidEmail),
  })
  const onSubmit = (values) => {
    userService.saveCompanyInfo(values)
      .then( () => {
        props.fetchUser()
        props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.settings_were_successfully_changed'))
      })
      .catch(err => props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Form>
          <Section title={t('user.settings.company_details')}>
            <div className="info-details info-details--standard">
              <ul>
                <li>
                  <div className="info-details__label info-details__label--shorter">
                    <label>{t('user.settings.company.name')}</label>
                  </div>
                  <div className="info-details__info">
                    <div className={'form-group form-group--fixed-size' + (formikProps.errors.companyName && formikProps.touched.companyName ? ' form-group--error' : '')}>
                      <Field className="form-group__input" type="text" name="companyName" disabled={!canUpdate} />
                      <ErrorMessage className="form-group__error" name="companyName" component="span" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info-details__label info-details__label--shorter">
                    <label>{t('user.settings.workspace.registration_code')}</label>
                  </div>
                  <div className="info-details__info">
                    <div className={'form-group form-group--fixed-size' + (formikProps.errors.registrationCode && formikProps.touched.registrationCode ? ' form-group--error' : '')}>
                      <Field className="form-group__input" type="text" name="registrationCode" disabled={!canUpdate} />
                      <ErrorMessage className="form-group__error" name="registrationCode" component="span" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info-details__label info-details__label--shorter">
                    <label>{t('user.settings.workspace.address')}</label>
                  </div>
                  <div className="info-details__info">
                    <div className={'form-group form-group--fixed-size' + (formikProps.errors.address && formikProps.touched.address ? ' form-group--error' : '')}>
                      <Field className="form-group__input" type="text" name="address" disabled={!canUpdate} />
                      <ErrorMessage className="form-group__error" name="address" component="span" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info-details__label info-details__label--shorter">
                    <label>{t('common.email')}</label>
                  </div>
                  <div className="info-details__info">
                    <div className={'form-group form-group--fixed-size' + (formikProps.errors.companyEmail && formikProps.touched.companyEmail ? ' form-group--error' : '')}>
                      <Field className="form-group__input" type="mail" name="companyEmail" disabled={!canUpdate} />
                      <ErrorMessage className="form-group__error" name="companyEmail" component="span" />
                      {user.companyEmailNotConfirmed && user.companyEmailNotConfirmed === formikProps.getFieldProps('companyEmail').value && (
                        <span className="form-group__comment">{t('user.settings.waiting_email_confirmation')}</span>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Section>
          <Section title={t('user.settings.email_settings')}>
            <div className={'form-group form-group__checkbox' + (formikProps.errors.sendFullySignNotifications && formikProps.touched.sendFullySignNotifications ? ' form-group--error' : '')} >
              <Field type="checkbox" name="sendFullySignNotifications" id="send-sign-notifications" disabled={!canUpdate} />
              <label htmlFor="send-sign-notifications" id="settings-checkbox">{t('user.settings.document_fully_sign_org_notification')}</label>
              <ErrorMessage className="form-group__error" name="sendFullySignNotifications" component="span" />
            </div>
          </Section>
          <Section>
            <h2 className="headline headline--standard d-flex">
              {t('user.settings.company.data_retention_policy')}
              {user.subscription !== 'flexible' && user.subscription !== 'limited_flexible' && (
                <IconTooltip tooltipText={t('user.document.available_from_flex_tooltip')} inline={true} newLine={true} flex={true} />
              )}
            </h2>
            <div className="info-details info-details--standard">
              <ul>
                <li>
                  <div className="info-details__label info-details__label--shorter">
                    <label>{t('user.settings.company.document_retention_duration')}</label>
                  </div>
                  <div className="info-details__info">
                    <div className="form-group form-group--fixed-size">
                      <input className="form-group__input" type="text" name="documentRetentionDuration" disabled={true} value={documentRetentionDuration} />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Section>
          <BottomBar />
        </Form>
      )}
    </Formik>
  )
}