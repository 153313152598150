// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { default as BottomBarDefault } from '../BottomBar'

export default function BottomBar(): React.Node {
  const { t } = useTranslation()

  return (
    <BottomBarDefault>
      <button type="submit" className="btn btn--primary ta-center">
        {t('common.save')}
      </button>
    </BottomBarDefault>
  )
}