function isUnpaidUser(userSubscription) {
  return userSubscription === 'start' || userSubscription === 'no_plan'
}

export const filterSignatureProducts = (signatureProducts, userSubscription) => {
  return signatureProducts.filter(p => p.name.includes(isUnpaidUser(userSubscription) ? 'start' : userSubscription))
}

export const filterGlobalSignatureProducts = (signatureProducts, userSubscription) => {
  return isUnpaidUser(userSubscription)
    ? signatureProducts.filter(p => p.name.includes('global_basic'))
    : signatureProducts.filter(p => p.categories.includes('global') && !p.name.includes('global_basic'))
}