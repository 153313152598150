export const SETTINGS_PROFILE = 'profile'
export const SETTINGS_CONTACTS = 'contacts'
export const SETTINGS_COMPANY = 'company'
export const SETTINGS_COMPANY_USERS = 'company-users'
export const SETTINGS_WORKSPACES = 'workspaces'
export const SETTINGS_BILLING = 'billing'
export const SETTINGS_DOCUMENT_CATEGORIES = 'document-categories'
export const SETTINGS_SYSTEM_USERS = 'system-users'
export const SETTINGS_ADDITIONAL = 'additional-settings'
export const SETTINGS_LICENSES = 'licenses'
export const SETTINGS_STATISTICS = 'statistics'

export const SETTINGS_TABS = Object.freeze([
  SETTINGS_PROFILE,
  SETTINGS_CONTACTS,
  SETTINGS_COMPANY_USERS,
  SETTINGS_WORKSPACES,
  SETTINGS_BILLING,
  SETTINGS_DOCUMENT_CATEGORIES,
  SETTINGS_SYSTEM_USERS,
  SETTINGS_ADDITIONAL,
  SETTINGS_LICENSES,
  SETTINGS_STATISTICS
])
export const MAX_DOCUMENT_UPLOAD_PER_MONTH = 3