import React from 'react'
import TabsList, { Tab } from '~/js/components/TabsList'
import Workspaces from '../../../../components/Workspaces'
import CompanyUsers from '../../../../components/CompanyUsers'
import StatusMessages from '../../../../containers/StatusMessages'
import ProfileSettings from '../../../../components/Settings/ProfileSettings'
import Contacts from '../../../../components/Contacts'
import BillingSettings from '../../../../components/Settings/BillingSettings'
import {
  SETTINGS_ADDITIONAL,
  SETTINGS_BILLING,
  SETTINGS_COMPANY,
  SETTINGS_COMPANY_USERS,
  SETTINGS_CONTACTS,
  SETTINGS_DOCUMENT_CATEGORIES,
  SETTINGS_LICENSES,
  SETTINGS_PROFILE,
  SETTINGS_STATISTICS,
  SETTINGS_WORKSPACES
} from '~/js/models/Settings'
import DocumentCategoriesSettings from '../../../../components/DocumentCategoriesSettings'
import PropTypes from 'prop-types'
import AdditionalSettings from '../../../../components/Settings/AdditionalSettings'
import Licenses from '~/js/components/Licenses'
import Statistics from '~/js/components/Statistics/Statistics'
import CompanySettings from '../../../../components/Settings/CompanySettings'

export default class Settings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tabs: this.getTabs(),
    }

    this.getTabs = this.getTabs.bind(this)
    this.updateTabs = this.updateTabs.bind(this)
    this.getActiveTab = this.getActiveTab.bind(this)
    this.onSettingsTabClick = this.onSettingsTabClick.bind(this)
    this.onSwitchWorkspace = this.onSwitchWorkspace.bind(this)
  }

  getTabs() {
    const { match } = this.props
    const category = match.params.category || SETTINGS_PROFILE

    return this.updateTabs(new Map([
      [
        'profile',
        {
          active: category === SETTINGS_PROFILE,
          hidden: false,
          title: 'user.settings.profile',
          number: null,
          category: SETTINGS_PROFILE,
        }
      ],
      [
        'billing',
        {
          active: category === SETTINGS_BILLING,
          hidden: true,
          title: 'user.settings.billing',
          number: null,
          category: SETTINGS_BILLING,
        }
      ],
      [
        'workspaces',
        {
          active: category === SETTINGS_WORKSPACES,
          hidden: false,
          title: 'user.settings.workspaces',
          number: null,
          category: SETTINGS_WORKSPACES,
        }
      ],
      [
        'contacts',
        {
          active: category === SETTINGS_CONTACTS,
          hidden: false,
          title: 'user.settings.contacts',
          number: null,
          category: SETTINGS_CONTACTS,
        }
      ],
      [
        'company',
        {
          active: category === SETTINGS_COMPANY,
          hidden: true,
          title: 'user.settings.company.settings',
          number: null,
          category: SETTINGS_COMPANY,
        }
      ],
      [
        'companyUsers',
        {
          active: category === SETTINGS_COMPANY_USERS,
          hidden: true,
          title: 'user.settings.company.users',
          number: null,
          category: SETTINGS_COMPANY_USERS,
        }
      ],
      [
        'documentCategories',
        {
          active: category === SETTINGS_DOCUMENT_CATEGORIES,
          hidden: true,
          title: 'user.settings.document_categories',
          number: null,
          category: SETTINGS_DOCUMENT_CATEGORIES,
        }
      ],
      [
        'licenses',
        {
          active: category === SETTINGS_LICENSES,
          hidden: true,
          title: 'user.settings.company.licenses',
          number: null,
          category: SETTINGS_LICENSES,
        }
      ],
      [
        'statistics',
        {
          active: category === SETTINGS_STATISTICS,
          hidden: true,
          title: 'user.settings.statistics_tab.title',
          number: null,
          category: SETTINGS_STATISTICS,
        }
      ],
      [
        'additionalSettings',
        {
          active: category === SETTINGS_ADDITIONAL,
          hidden: false,
          title: 'user.settings.additional_settings_tab.title',
          number: null,
          category: SETTINGS_ADDITIONAL,
        }
      ]
    ]))
  }

  getActiveTab() {
    return this.state.tabs.find(tab => tab.active)
  }

  onSettingsTabClick(tabClicked) {
    const { tabs } = this.state
    const { match, history } = this.props

    tabs.forEach(tab => tab.active = false)
    tabClicked.active = true

    this.setState({ tabs })
    const newPath = match.path.indexOf(':category') !== -1
      ? match.path.replace(/:category/, tabClicked.category)
      : `${match.path}/${tabClicked.category}`

    history.push(newPath)
  }

  onSwitchWorkspace(workspaceId) {
    return this.props.switchWorkspace(workspaceId)
      .then(() => this.props.history.push('/workspace/settings'))
  }

  updateTabs(tabs) {
    const { user: { subscription, workspaceId, permissions } } = this.props
    const isCompanyWorkspace = !!workspaceId
    const isManagerOrHigher = permissions.isManager || permissions.isAdmin
    const isMultiUserSubscription = subscription === 'teams'
      || subscription === 'flexible'
      || subscription === 'limited_flexible'
    const isAnyPaidSubscription = isMultiUserSubscription || subscription === 'professional'

    if (isCompanyWorkspace && isManagerOrHigher) {
      tabs.get('billing').hidden = false
      tabs.get('company').hidden = false
    } else if (!isCompanyWorkspace) {
      tabs.get('billing').hidden = false
    }

    if (isCompanyWorkspace && isManagerOrHigher && isMultiUserSubscription) {
      tabs.get('companyUsers').hidden = false
    }

    if (isCompanyWorkspace && isManagerOrHigher && isAnyPaidSubscription) {
      tabs.get('documentCategories').hidden = false
    }

    if (isCompanyWorkspace && permissions.isAdmin && subscription === 'teams') {
      tabs.get('licenses').hidden = false
    }

    if (isCompanyWorkspace && permissions.isAdmin && isMultiUserSubscription) {
      tabs.get('statistics').hidden = false
    }

    return tabs
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({ tabs: this.getTabs() })
    }
  }

  componentDidMount() {
    this.isContentShrink()
  }

  isContentShrink() {
    if (document.querySelector('#sidebar').className === 'shrink') {
      document.querySelector('main').classList.toggle('shrink')
      document.querySelector('#bottom-bar').classList.toggle('shrink')
    }
  }

  render() {
    const { t, user, fetchUser, showStatusMessage } = this.props
    const { tabs } = this.state

    return (
      <React.Fragment>
        <main>
          <div id="header">
            <div className="grid">
              <h1 className="page-title">{t('settings')}</h1>
            </div>
          </div>
          <TabsList centered={false}>
            {Array.from(tabs, ([key, tab]) => (
              !tab.hidden ?
                <Tab
                  key={`tab-${key}`}
                  active={tab.active}
                  title={t(tab.title)}
                  onClick={this.onSettingsTabClick.bind(this, tab)}
                /> : null
            ))}
          </TabsList>
          <section id="tabs-content">
            <div className="grid grid--flex">
              <div className="tabs-content">
                <div className={'tabs-content__tab' + (tabs.get('profile').active ? ' active' : '')}>
                  <ProfileSettings
                    t={this.props.t}
                    history={this.props.history}
                    resetUser={this.props.resetUser}
                    user={user}
                    showStatusMessage={showStatusMessage}
                    fetchUser={fetchUser}
                    switchWorkspace={this.props.switchWorkspace}
                  />
                </div>
                <div className={'tabs-content__tab' + (tabs.get('contacts').active ? ' active' : '')}>
                  <Contacts
                    history={this.props.history}
                    location={this.props.location}
                    t={t}
                    showStatusMessage={showStatusMessage}
                  />
                </div>
                {user.workspaceId && (
                  <div className={'tabs-content__tab' + (tabs.get('companyUsers').active ? ' active' : '')}>
                    <CompanyUsers
                      history={this.props.history}
                      location={this.props.location}
                      t={t}
                      user={user}
                      showStatusMessage={showStatusMessage}
                      workspaceId={user.workspaceId}
                      fetchUser={fetchUser}
                    />
                  </div>
                )}
                {tabs.get('company').active && (
                  <div className="tabs-content__tab active">
                    <CompanySettings
                      user={user}
                      showStatusMessage={showStatusMessage}
                      fetchUser={fetchUser}
                    />
                  </div>
                )}
                <div className={'tabs-content__tab' + (tabs.get('workspaces').active ? ' active' : '')}>
                  <Workspaces
                    history={this.props.history}
                    location={this.props.location}
                    t={t}
                    user={user}
                    fetchUser={fetchUser}
                    showStatusMessage={showStatusMessage}
                    onSwitchWorkspace={this.onSwitchWorkspace}
                    isSwitching={this.props.isSwitching}
                  />
                </div>
                {tabs.get('billing').active && (
                  <div className="tabs-content__tab active">
                    <BillingSettings
                      t={t}
                      user={user}
                      fetchUser={fetchUser}
                      showStatusMessage={showStatusMessage}
                    />
                  </div>
                )}
                {tabs.get('documentCategories').active && (
                  <div className="tabs-content__tab active">
                    <DocumentCategoriesSettings
                      history={this.props.history}
                      location={this.props.location}
                      t={t}
                      user={user}
                      fetchUser={fetchUser}
                      showStatusMessage={showStatusMessage}
                    />
                  </div>
                )}
                {(tabs.get('licenses').active &&
                  <div className="tabs-content__tab active">
                    <Licenses
                      history={this.props.history}
                      location={this.props.location}
                      t={t}
                      user={user}
                      showStatusMessage={showStatusMessage}
                      workspaceId={user.workspaceId}
                      fetchUser={fetchUser}
                    />
                  </div>
                )}
                {user.workspaceId && (
                  <div className={'tabs-content__tab' + (tabs.get('statistics').active ? ' active' : '')}>
                    <Statistics
                      t={t}
                      showStatusMessage={showStatusMessage}
                    />
                  </div>
                )}
                <div className={'tabs-content__tab' + (tabs.get('additionalSettings').active ? ' active' : '')}>
                  <AdditionalSettings
                    history={this.props.history}
                    location={this.props.location}
                    t={t}
                    showStatusMessage={showStatusMessage}
                    user={user}
                    fetchUser={fetchUser}
                  />
                </div>
              </div>
            </div>
          </section>
        </main>
        <StatusMessages />
      </React.Fragment>
    )
  }
}

Settings.propTypes = {
  t: PropTypes.func,
  resetUser: PropTypes.func,
  showStatusMessage: PropTypes.func,
  fetchUser: PropTypes.func,
  switchWorkspace: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  isSwitching: PropTypes.bool,
}