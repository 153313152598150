// @flow

import * as React from 'react'

type Props = {
  className?: string,
  children?: any,
}

export default function BottomBar({ className, children }: Props): React.Node {
  return (
    <div id="bottom-bar" className={`bottom-bar${className ? ' ' + className : ''}`}>
      {children}
    </div>
  )
}