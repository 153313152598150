import React from 'react'
import ModalRemove from '../../components/ModalRemove'
import * as workspaceService from '~/js/services/user/workspace'
import Pagination from '~/js/components/Pagination'
import { Workspace, WorkspacesList } from '../WorkspacesList'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import InputSearch from '../InputSearch'
import StatusModal from '../StatusModal'
import ModalUserEnableFlexibleSubscription from '../ModalUserEnableFlexibleSubscription'
import * as userService from '~/js/services/user/user'
import ModalQuit from '../ModalQuit'
import PropTypes from 'prop-types'

const pageSize = 10

export default class Workspaces extends React.Component {
  constructor(props) {
    super(props)

    const queryString = this.parseQueryString()
    const page = queryString.page || 1

    this.state = {
      companies: [],
      pagination: {
        page: page,
        offset: this.getOffset(page),
        limit: pageSize,
        total: undefined,
      },
      modalRemove: {
        active: false,
        workspaceIndex: undefined,
      },
      modalEnableFlexibleSubscription: {
        active: false,
        companyIndex: undefined,
      },
      modalQuit: {
        active: false,
        companyIndex: undefined,
      },
      searchValue: '',
      statusModal: {
        active: false
      },
    }

    this.enableFlexibleSubscription = this.enableFlexibleSubscription.bind(this)
    this.onModalQuitClick = this.onModalQuitClick.bind(this)
    this.onModalRemoveWorkspaceClick = this.onModalRemoveWorkspaceClick.bind(this)
    this.parseQueryString = this.parseQueryString.bind(this)
    this.fetchCompanies = this.fetchCompanies.bind(this)
    this.setCompanies = this.setCompanies.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onPaginationNextClick = this.onPaginationNextClick.bind(this)
    this.onPaginationPreviousClick = this.onPaginationPreviousClick.bind(this)
    this.setPaginationPage = this.setPaginationPage.bind(this)

    this.fetchCompanies()
  }

  getOffset(page) {
    return (page - 1) * pageSize
  }

  onEnableFlexibleSubscriptionClick(index) {
    this.setState({
      modalEnableFlexibleSubscription: {
        active: true,
        companyIndex: index
      }
    })
  }

  onWorkspaceDeleteClick(index) {
    this.setState({
      modalRemove: {
        active: true,
        workspaceIndex: index,
      },
    })
  }

  onWorkspaceSwitchClick(index) {
    const workspace = this.state.companies[index]

    this.props.onSwitchWorkspace(workspace.id)
  }

  onModalRemoveWorkspaceClick() {
    const { t } = this.props
    const { companies, modalRemove } = this.state
    const company = companies[modalRemove.workspaceIndex]

    workspaceService
      .removeWorkspace(company.id)
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.workspace.workspace_was_successfully_removed')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalRemove: { active: false } })
        this.fetchCompanies()
        this.props.fetchUser()
      })
  }

  parseQueryString() {
    const search = this.props.location.search || ''
    const match = search.match(/page=(\d)?/)

    return {
      page: match ? parseInt(match[1], 10) : undefined,
    }
  }

  fetchCompanies() {
    const { pagination, searchValue } = this.state

    workspaceService
      .getWorkspaces(this.props.user, pagination.offset, pagination.limit, searchValue, true)
      .then(data => this.setCompanies(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setCompanies(data) {
    const { data: companies, totalCount } = data

    this.setState(prevState => ({
      companies,
      pagination: {
        ...prevState.pagination,
        total: totalCount,
      },
    }))
  }

  onSearchChange(value) {
    this.setState({ searchValue: value, pagination: { page: 1, offset: this.getOffset(1), limit: pageSize } })
    this.setPaginationPage(this.state.pagination.page)
    this.fetchCompanies()
  }

  onWorkspaceQuitClick(index) {
    this.setState({
      modalQuit: {
        active: true,
        companyIndex: index,
      },
    })
  }

  onModalQuitClick() {
    const workspace = this.state.companies[this.state.modalQuit.companyIndex]
    const { t } = this.props

    workspaceService
      .quitWorkspace(workspace.id)
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.workspace.workspace_was_successfully_removed')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalQuit: { active: false } })
        if (workspace.id === this.props.user.workspaceId) {
          this.props.onSwitchWorkspace(null)
          return
        }

        this.fetchCompanies()
        this.props.fetchUser()
      })
  }

  onPaginationNextClick() {
    this.setPaginationPage(this.state.pagination.page + 1)
  }

  onPaginationPreviousClick() {
    this.setPaginationPage(this.state.pagination.page - 1)
  }

  setPaginationPage(page) {
    this.props.history.push({
      search: `?page=${page}`,
    })

    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
        offset: this.getOffset(page),
      }
    }))
  }

  enableFlexibleSubscription(noUnlimitedSignatures) {
    const { t } = this.props
    const { companies, modalEnableFlexibleSubscription } = this.state
    const company = companies[modalEnableFlexibleSubscription.companyIndex]

    return userService
      .enableFlexibleSubscription(company.id, { isBusiness: true, noUnlimitedSignatures: noUnlimitedSignatures })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.user.subscription_was_successfully_enabled')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalEnableFlexibleSubscription: { active: false } })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this

    if (state.pagination.page !== prevState.pagination.page) {
      this.fetchCompanies()
    }
  }

  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <div className="settings__top">
          <div className="settings__top__left">
            <div className="form-wrap form-wrap--search">
              <InputSearch
                value={this.state.searchValue}
                onChange={this.onSearchChange}
                wait={this.props.wait}
              />
            </div>
          </div>
        </div>
        <div className="users-list users-list--workspace">
          <div className="table table--settings">
            <ul className="table__head">
              <li>
                <label>{t('user.settings.workspace.workspace_name')}</label>
              </li>
              <li>
                <label>{t('user.settings.workspace.your_role')}</label>
              </li>
            </ul>
            <WorkspacesList>
              {this.state.companies.map((company, index) => (
                <Workspace
                  key={company.id}
                  t={t}
                  id={company.id}
                  isBusiness={!!company.id}
                  name={company.businessName}
                  hexColor={company.hexColor}
                  user={this.props.user}
                  role={company.businessPersonRole}
                  canSwitchTo={company.permissions.canSwitchTo && company.id !== this.props.user.workspaceId}
                  onEnableFlexibleSubscriptionClick={this.onEnableFlexibleSubscriptionClick.bind(this, index)}
                  canDelete={company.permissions.canDelete}
                  canQuit={company.id !== null}
                  onSwitchClick={this.onWorkspaceSwitchClick.bind(this, index)}
                  onQuitClick={this.onWorkspaceQuitClick.bind(this, index)}
                  onDeleteClick={this.onWorkspaceDeleteClick.bind(this, index)}
                />
              ))}
            </WorkspacesList>
          </div>
          <Pagination
            offset={this.state.pagination.offset}
            limit={this.state.pagination.limit}
            total={this.state.pagination.total}
            onNextClick={this.onPaginationNextClick}
            onPreviousClick={this.onPaginationPreviousClick}
          />
        </div>
        <ModalRemove
          active={this.state.modalRemove.active}
          title={t('user.settings.workspace.remove_workspace')}
          message={t('user.settings.workspace.are_you_sure_you_want_to_remove')}
          onCloseClick={() => this.setState({ modalRemove: { active: false } })}
          onRemoveClick={this.onModalRemoveWorkspaceClick}
        />
        <ModalUserEnableFlexibleSubscription
          t={t}
          active={this.state.modalEnableFlexibleSubscription.active}
          title={t('user.settings.user.enable_flexible_subscription')}
          business={true}
          onCloseClick={() => this.setState({ modalEnableFlexibleSubscription: { active: false } })}
          onEnableClick={this.enableFlexibleSubscription}
        />
        <ModalQuit
          active={this.state.modalQuit.active}
          title={t('user.settings.company_user.quit_team')}
          message={t('user.settings.company_user.are_you_sure_you_want_to_quit_team')}
          onCloseClick={() => this.setState({ modalQuit: { active: false } })}
          onRemoveClick={this.onModalQuitClick}
        />
        <StatusModal
          t={t}
          active={this.state.statusModal.active}
          title={t('user.settings.company_user.confirm_email')}
          onCloseClick={() => this.setState({ statusModal: { active: false } })}
        />
      </React.Fragment>
    )
  }
}

Workspaces.defaultProps = {
  wait: 500,
}

Workspaces.propTypes = {
  onSwitchWorkspace: PropTypes.func,
  t: PropTypes.func,
  user: PropTypes.object,
  workspaceId: PropTypes.number,
  showStatusMessage: PropTypes.func,
  fetchUser: PropTypes.func,
  location: PropTypes.object,
  wait: PropTypes.number,
  history: PropTypes.object
}