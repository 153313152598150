import React from 'react'
import PropTypes from 'prop-types'
import DropzoneComponent from '~/js/components/DropzoneComponent'
import StatusMessages from '~/js/containers/StatusMessages'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import { DOCUMENT_UPLOAD_TYPE_VALIDATION } from '../../../../models/Document'
import Loader from '../../../../components/Loader'
import ModalValidationLimitReached from '../../../../components/ModalValidationLimitReached'
import { validationInfo } from '../../../../services/user/user'
import { SM_TYPE_ERROR } from '../../../../components/StatusMessages'
import ModalAdditionalAndGlobalSignatures from '../../../../components/ModalAdditionalAndGlobalSignatures'
import { filterSignatureProducts, filterGlobalSignatureProducts } from '../../../../utils/product'

export default class UploadDocumentValidate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      modalActive: false,
      validation: {},
      modalAdditionalAndGlobalSignatures: {
        active: false,
      },
    }

    this.isContentShrink = this.isContentShrink.bind(this)
    this.onUploadSuccess = this.onUploadSuccess.bind(this)
    this.fetchValidationInfo = this.fetchValidationInfo.bind(this)
    this.onMaxFileCountExceeded = this.onMaxFileCountExceeded.bind(this)
    this.onCtaBuyAdditionalSignatures = this.onCtaBuyAdditionalSignatures.bind(this)
    this.closeAdditionalAndGlobalSignaturesModal = this.closeAdditionalAndGlobalSignaturesModal.bind(this)
    this.onBuyClick = this.onBuyClick.bind(this)
  }

  isContentShrink() {
    if (document.querySelector('#sidebar').className === 'shrink' && document.querySelector('main')) {
      document.querySelector('main').classList.toggle('shrink')
    }
  }

  componentDidMount() {
    this.isContentShrink()
    this.setState({
      validation: this.fetchValidationInfo().promise
    })
  }

  onUploadSuccess(documentUuid, callback) {
    const { fetchUser, history } = this.props
    fetchUser()
    typeof callback === 'function' && callback()
    history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS_VALIDATE_PREVIEW, { documentId: typeof documentUuid === 'string' ? documentUuid : documentUuid[0] }))
  }

  fetchValidationInfo() {
    const { promise, cancel } = validationInfo(this.props.user.id)
    promise
      .then(data => this.setState({ loading: false, modalActive: !data.data.canValidate, validation: data.data }))
      .catch((error) => {
        this.setState({ loading: false })
        this.props.showStatusMessage(SM_TYPE_ERROR, error.message)
      })
    return { promise, cancel }
  }

  onMaxFileCountExceeded() {
    this.setState({ modalActive: true })
  }

  onCtaBuyAdditionalSignatures() {
    this.setState({
      modalAdditionalAndGlobalSignatures: {
        active: true,
      },
      modalActive: false
    })
  }

  closeAdditionalAndGlobalSignaturesModal() {
    this.setState({ modalAdditionalAndGlobalSignatures: { active: false } })
  }

  onBuyClick(selectedProductName, selectedProductPrice, selectedProductId, selectedProductCategory) {
    const product = this.props.signatureProducts.find(itm => itm.id === selectedProductId)

    this.props.clearCart()
    this.props.addCartItem(product)
    this.props.history.push(
      selectedProductCategory === 'signature' ?
        generatePath(ROUTE_NAMES.USER_PRICING_CHECKOUT) :
        generatePath(ROUTE_NAMES.SUBSCRIPTION_WORKSPACE_ASSIGN)
    )
  }

  render() {
    const { t, user } = this.props
    const { loading, modalActive } = this.state
    return (
      <>
        <main>
          {loading && (
            <Loader loadingText={t('user.validation.loading_data')} />
          )}
          {!loading &&
            <DropzoneComponent
              user={this.props.user}
              showStatusMessage={this.props.showStatusMessage}
              onUploadSuccess={this.onUploadSuccess}
              uploadType={DOCUMENT_UPLOAD_TYPE_VALIDATION}
              multipleFiles={true}
              maxFileCount={this.state.validation.left}
              disableComponent={!this.state.validation.canValidate}
              disableComponentNotice={t('user.validation.limit_alert_msg', { maxLimit: user.validation.allowed })}
              maxFileSizeLimit={this.props.user.maxFileSizeLimit}
            />
          }
        </main>
        <StatusMessages fullWidth={true} />
        <ModalValidationLimitReached
          openModalAdditionalSignatures={this.onCtaBuyAdditionalSignatures}
          active={modalActive}
          onCloseClick={() => this.setState({ modalActive: false })}
          {...this.props}
          maxValidationslimit={user.validation.allowed}
        />
        <ModalAdditionalAndGlobalSignatures
          t={t}
          user={user}
          active={this.state.modalAdditionalAndGlobalSignatures.active}
          onCloseClick={this.closeAdditionalAndGlobalSignaturesModal}
          signaturesProducts={filterSignatureProducts(this.props.signatureProducts, user.subscription)}
          globalSignaturesProducts={filterGlobalSignatureProducts(this.props.signatureProducts, user.subscription)}
          onBuyClick={this.onBuyClick}
          authMethod={user.authMethod}
        />
      </>
    )
  }
}

UploadDocumentValidate.propTypes = {
  fetchUser: PropTypes.func,
  history: PropTypes.object,
  user: PropTypes.object,
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  signatureProducts: PropTypes.array,
  clearCart: PropTypes.func,
  addCartItem: PropTypes.func,
}
