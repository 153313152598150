import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { isValidEmail } from '../utils/email'

const withEmailValidation = (EmailValidationComponent) => {
  class NewEmailValidationComponent extends Component {
    render() {
      return <EmailValidationComponent
        {...this.props}
        isValidEmail={isValidEmail}
      />
    }
  }

  return withTranslation()(NewEmailValidationComponent)
}

export default withEmailValidation