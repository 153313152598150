import _debounce from 'lodash/debounce'
import apiClient from '~/js/utils/apiClientDocs'
import { getUserNamespace } from './serviceHelpers'

/* global dataLayer */

export const AUTH_TYPE_SMARTID = 'smartid'
export const AUTH_TYPE_MOBILEID = 'mobileid'
export const AUTH_TYPE_ZEALID = 'zealid'
export const AUTH_TYPE_SMARTCARD = 'smartcardusb'
export const AUTH_TYPE_SIMPLYSIGN = 'simplysign'
export const AUTH_TYPE_LTID = 'ltid'

export function initializeLogin(type, params) {
  return apiClient
    .post(`v2/${type}/login`, params)
    .then(({ data }) => data)
}

export function getBusinessLogoUrl(businessId) {
  return apiClient
    .get(`/v1/${getUserNamespace()}/workspace/${businessId}/logo_url`)
    .then(({ data }) => data)
}

export function getTemporaryBusinessLogoUrl(businessId) {
  return apiClient
    .get(`/v1/${getUserNamespace()}/workspace/${businessId}/logo_url?temporary=1`)
    .then(({ data }) => data)
}

export function pollLoginStatus(type, params, timeLimit, forControlCode) {
  let canceled = false
  const timeLimitMs = timeLimit * 1000
  const startTime = new Date()
  const promise = new Promise(
    (resolve, reject) => {
      const requestStatus = () => {
        if (new Date() - startTime > timeLimitMs) {
          return reject(new Error('Timeout while logging in.'))
        }

        apiClient
          .get(`v2/${type}/login/session/${params.token}`)
          .then(({ data }) => {
            if (canceled) { return reject() }
            if (forControlCode && data.data.control_code) { return resolve(data) }
            if (data.status === 'ok') { return resolve(data) }

            requestStatusDebounced()
          })
          .catch(reject)
      }
      const requestStatusDebounced = _debounce(requestStatus, 1000)

      requestStatusDebounced()
    }
  )

  return {
    promise,
    cancel: () => canceled = true,
  }
}

export function finalizeLogin(sessionId, serialNumber, workspaceId, workspace) {
  const formData = new FormData()
  formData.set('sessionId', sessionId)
  formData.set('serialNumber', serialNumber)
  formData.set('workspaceId', workspaceId)

  return apiClient
    .request({
      method: 'post',
      url: `v1/${workspace}/login`,
      data: formData,
    })
    .then(({ data }) => data)
}

export function getClientType(user) {
  return apiClient
    .get(`v1/${getUserNamespace(user)}/client-type`)
    .then(({ data }) => data)
}

export function setClientType(user) {
  if (typeof dataLayer === 'undefined') {
    return
  }

  getClientType(user)
    .then(({ data: clientType }) => {
      let add = true

      dataLayer.forEach((elm, idx) => {
        if (elm !== undefined && elm.client_type !== undefined) {
          // eslint-disable-next-line camelcase
          dataLayer[idx] = { client_type: clientType }
          add = false
        }
      })

      if (add) {
        // eslint-disable-next-line camelcase
        dataLayer.push({ client_type: clientType })
      }
    })
    .catch(() => {})
}

export function getInfo(user) {
  setClientType(user)

  return apiClient
    .get(`v1/${getUserNamespace(user)}/info`)
    .then(({ data }) => data)
}

export function getInfoBySessionId(sessionId) {
  return apiClient
    .get('v1/session/user-info', { headers: { 'X-SESSION-ID': sessionId } })
    .then(({ data }) => data)
}

export function switchWorkspace(workspaceId) {
  const url = workspaceId ? `${getUserNamespace()}/switch-workspace/${workspaceId}` : `${getUserNamespace()}/switch-workspace`

  return apiClient
    .post(url)
    .then(({ data }) => data)
}

export function saveCompanyInfo(values) {
  return apiClient
    .post('/workspace/edit', {
      name: values.companyName,
      registrationCode: values.registrationCode,
      address: values.address,
      email: values.companyEmail,
      sendFullySignNotifications: values.sendFullySignNotifications,
    })
    .then(res => {
      return res.data
    })
}

export function saveUserInfo(values, user) {
  return apiClient
    .post(`/${getUserNamespace(user)}/user-info/edit`, {
      phoneNumber: values.phoneNumber,
      email: values.businessPersonEmail || values.email,
      acceptsNewsletter: values.acceptsNewsletter,
      docSignNotification: values.docSignNotification,
      docFullySignNotification: values.docFullySignNotification
    })
    .then(res => {
      return res.data
    })
}

export function saveCompanyUserInfo(values) {
  return apiClient
    .post('/workspace/company-user-info/edit', {
      businessPersonEmail: values.businessPersonEmail
    })
    .then(res => {
      return res.data
    })
}

export function getBillingDetails() {
  return apiClient
    .get(`/v1/${getUserNamespace()}/info/billing`)
    .then(({ data }) => data)
}

export function updateBillingDetails(params) {
  return apiClient
    .put(`/v1/${getUserNamespace()}/info/billing`, params)
    .then(({ data }) => data)
}

export function getDataRetentionPolicy() {
  return apiClient
    .get(`/v1/${getUserNamespace()}/info/data-retention-policy`)
    .then(({ data }) => data)
}

export function getInvoicesList() {
  return apiClient
    .get(`/v1/${getUserNamespace()}/invoice`)
    .then(({ data }) => data)
}

export function getInvoicePdfUrl(invoiceId) {
  return `/api/v1/${getUserNamespace()}/invoice/${invoiceId}/pdf`
}

export function register(params) {
  return apiClient
    .post(`v1/${getUserNamespace()}/register`, params)
    .then(({ data }) => data)
}

export function getSessionStatus() {
  return apiClient
    .get('v1/session/status')
    .then(({ data }) => data)
}

export function extendSession() {
  return apiClient
    .post('v1/session/extend')
}

export function dontShowWorkspaceCategoriesMessageModalAgain() {
  return apiClient
    .post('v1/workspace/dont-show-modal')
    .then(({ data }) => data)
}

export function getUsers(user, offset, limit, query) {
  const params = { offset, limit }

  if (query) {
    params.query = query
  }

  return apiClient
    .get(`v1/${getUserNamespace(user)}/users`, { params })
    .then(({ data }) => data)
}

export function enableFlexibleSubscription(customerId, params) {
  return apiClient
    .post(`v1/${getUserNamespace()}/users/enable-flexible-subscription/${customerId}`, params)
    .then(({ data }) => data)
}

export function deleteAccount(userId, payload) {
  return apiClient
    .post(`v1/${getUserNamespace()}/${userId}`, payload)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response.data))
}

export function getQRCode() {
  return apiClient
    .request({
      method: 'post',
      url: 'v1/zealid/login'
    })
    .then(({ data }) => data)
}

export function getToken(workspace, code, token) {
  return apiClient
    .request({
      method: 'post',
      url: `v1/${workspace}/status`,
      data: { code: code, token: token }
    })
    .then(({ data }) => data)
}

export function validationInfo() {
  const controller = new AbortController()
  const cancelTokenSource = apiClient.defaults.cancelTokenSource()

  return {
    promise: apiClient
      .get(`v1/${getUserNamespace()}/info/validation`, {
        cancelToken: cancelTokenSource.token,
        signal: controller.signal
      })
      .then(response => response.data)
      .catch(err => {
        return err.response && err.response.data ? Promise.reject(err.response.data) : Promise.reject(err)
      }),
    cancel: (reason) => {
      typeof cancelTokenSource === 'object' && typeof cancelTokenSource.cancel === 'function' ? cancelTokenSource.cancel(reason) : (typeof controller === 'object' && typeof controller.abort === 'function' ? controller.abort(reason) : null)
    }
  }
}

export function updateAdditionalSettings(values, user) {
  return apiClient
    .post(`/${getUserNamespace(user)}/additional-settings`, {
      ...values
    })
    .then(res => {
      return res.data
    })
}

export function loginSmartCardUsb(params) {
  return apiClient
    .post('/v1/smart-card-usb/login', params)
    .then(({ data }) => data)
}

export function loginSmartCardUsbStatus(token, params) {
  return apiClient
    .get(`/v1/smart-card-usb/status/${token}`, { params } )
    .then(({ data }) => data)
}

export function loginSmartCardUsbResponse(pin, hash) {
  return apiClient
    .post(
      'http://localhost:5000/sign',
      { pin, type: 'auth', datatobesigned: hash }
    )
    .then(({ data }) => data)
}

export function updateFirstSignature(firstSignature) {
  return apiClient
    .post(`/${getUserNamespace()}/update-first-signature`, {
      firstSignature: firstSignature
    })
    .then(res => {
      return res.data
    })
}

export function isUserBusiness(user) {
  return user.workspaceId !== null
}

export function getSimplySignRedirectInfo() {
  return apiClient
    .request({
      method: 'post',
      url: 'v1/simplysign/login'
    })
    .then(({ data }) => data)
}

export function getSimplySignAccessToken(code) {
  return apiClient
    .request({
      method: 'post',
      url: 'v1/simplysign/token',
      data: { code: code }
    })
    .then(({ data }) => data)
}

export function confirmNewsletterDecision(user, isNewsletterAccepted) {
  return apiClient
    .post(`/v1/${getUserNamespace(user)}/newsletter/update`, {
      isNewsletterAccepted
    })
}