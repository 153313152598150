import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import uploadDocuments from '../../../img/illustration/upload-documents.svg'
import Modal from '../Modal'

class ModalAdditionalAndGlobalSignatures extends React.Component {
  constructor(props) {
    super(props)

    this.onContentClick = this.onContentClick.bind(this)
    this.onLessSignaturesClick = this.onLessSignaturesClick.bind(this)
    this.onMoreSignaturesClick = this.onMoreSignaturesClick.bind(this)
    this.getCurrentProductList = this.getCurrentProductList.bind(this)
    this.onToggleTab = this.onToggleTab.bind(this)

    const tabIndex = props.authMethod && 'zealid' === props.authMethod.toLowerCase() ? 1 : 0
    const signaturesProducts = this.getCurrentProductList(tabIndex)

    this.state = {
      tabIndex,
      selectedProductList: signaturesProducts,
      chosenSignaturesProduct: {
        product: props.signaturesProducts[0],
        index: 0
      }
    }
  }

  onMoreSignaturesClick() {
    if (this.state.selectedProductList[this.state.chosenSignaturesProduct.index + 1]) {
      this.setState({
        chosenSignaturesProduct: {
          product: this.state.selectedProductList[this.state.chosenSignaturesProduct.index + 1],
          index: this.state.chosenSignaturesProduct.index + 1
        }
      })
    }
  }

  onLessSignaturesClick() {
    if (this.state.chosenSignaturesProduct.index > 0) {
      this.setState({
        chosenSignaturesProduct: {
          product: this.state.selectedProductList[this.state.chosenSignaturesProduct.index - 1],
          index: this.state.chosenSignaturesProduct.index - 1
        }
      })
    }
  }

  componentDidMount() {
    this.onToggleTab(this.state.tabIndex)
  }

  componentDidUpdate(prevProps) {
    const { signaturesProducts } = this.props

    if (signaturesProducts.length !== prevProps.signaturesProducts.length) {
      this.setState({
        chosenSignaturesProduct: {
          product: signaturesProducts[0],
          index: 0
        }
      })
    }
  }

  onContentClick(event) {
    event.stopPropagation()
  }

  getCurrentProductList(tabIndex) {
    return (tabIndex === 0 ? this.props.signaturesProducts : this.props.globalSignaturesProducts).sort((a, b) => a.signaturesQuantity - b.signaturesQuantity)
  }

  onToggleTab(index) {
    const productList = this.getCurrentProductList(index)
    this.setState({
      selectedProductList: productList,
      chosenSignaturesProduct: {
        product: productList[0],
        index: 0
      },
      tabIndex: index
    })
  }

  render() {
    const { t, active } = this.props
    const { selectedProductList, chosenSignaturesProduct: { product }, tabIndex } = this.state

    if (!active || !product) {
      return null
    }

    return (
      <Modal
        active={active}
        onCloseClick={this.props.onCloseClick}
        size="large"
      >
        <div className="pricing-table__modal">
          <div className="pricing-table__modal-content">
            <div className="pricing-table__modal-img">
              <img src={uploadDocuments} alt="Upload Documents" />
            </div>
            <div className="pricing-table__row pricing-table__row--signatures">
              <div className="pricing-table__row-heading">{t('user.pricing.buy_signatures')}</div>
              <div className="double-btn">
                <a onClick={() => this.onToggleTab(0)} className={tabIndex === 0 ? 'active': ''} >{t('user.pricing.additional_signatures')}</a>
                <a onClick={() => this.onToggleTab(1)} className={tabIndex === 1 ? 'active': ''}>{t('user.pricing.zealid_signatures')}</a>
              </div>
              <div className="double-pricing-helper">
                {tabIndex === 0 && (<p>{t('user.pricing.additional_details')}</p>)}
                {tabIndex === 1 && (<p>{t('user.pricing.zealid_details')}</p>)}
              </div>
              <div className="pricing-table__sub-rows" id="additionalSection" >
                <div className="pricing-table__sub-row">
                  <div className="pricing-table__sub-row-col">{t('user.pricing.signature_count')}</div>
                  <div className="pricing-table__sub-row-col">
                    <div className="form-group">
                      <div className="input-minus-plus">
                        {selectedProductList.length > 1 && (
                          <button
                            className="btn"
                            onClick={this.onLessSignaturesClick}
                            disabled={this.state.chosenSignaturesProduct.index === 0}
                          >-
                          </button>
                        )}
                        <input className="form-group__input ta-center" type="text" value={product.signaturesQuantity} readOnly={true} />
                        {selectedProductList.length > 1 && (
                          <button
                            className="btn"
                            onClick={this.onMoreSignaturesClick}
                            disabled={this.state.chosenSignaturesProduct.index + 1 === selectedProductList.length}
                          >+
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {product.validationsLimit > 0 &&
                  <div className="pricing-table__sub-row">
                    <div className="pricing-table__sub-row-col">{t('user.pricing.document_validations_count')}</div>
                    <div className="pricing-table__sub-row-col">{product.validationsLimit}</div>
                  </div>
                }
                <div className="pricing-table__sub-row pricing-table__sub-row--btop">
                  <div className="pricing-table__sub-row-col">{t('user.pricing.signature_price')}</div>
                  <div className="pricing-table__sub-row-col">{product.signaturePrice}</div>
                </div>
                <div className="pricing-table__sub-row pricing-table__sub-row--btop">
                  <div className="pricing-table__sub-row-col">{t('user.pricing.total_excl_vat')}</div>
                  <div className="pricing-table__sub-row-col">{product.price}</div>
                </div>
                <div className="pricing-table__sub-row pricing-table__sub-row--btop">
                  <div className="pricing-table__sub-row-col">{t('user.pricing.total_incl_vat')}</div>
                  <div className="pricing-table__sub-row-col">{product.priceIncludingVat}</div>
                </div>
              </div >
              <button
                className="btn btn--primary"
                onClick={() => this.props.onBuyClick(product.name, product.price, product.id, product.category)}
              >
                {t('common.continue')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(ModalAdditionalAndGlobalSignatures)

ModalAdditionalAndGlobalSignatures.propTypes = {
  t: PropTypes.func,
  signaturesProducts: PropTypes.array,
  globalSignaturesProducts: PropTypes.array,
  active: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onBuyClick: PropTypes.func,
  authMethod: PropTypes.string,
}
